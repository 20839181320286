module.exports = {
  'Popular products': {
    en: 'Popular products',
    et: 'Populaarsed tooted',
    de: 'Beliebte Produkte',
    fi: 'Suositut tuotteet',
    ru: 'Популярные товары',
  },
  Products: {
    et: 'Tooted',
    en: 'Products',
    de: 'Produkte',
    fi: 'Tuotteet',
    ru: 'Товары',
  },
  About: {
    et: 'Meist',
    en: 'About',
    de: 'Info über unsere Firma',
    fi: 'Tietoa meistä',
    ru: 'О нас',
  },
  'Contact us': {
    et: 'Kontakteeru meiega',
    en: 'Contact us',
    de: 'Kontaktieren Sie uns',
    fi: 'Ota yhteyttä',
    ru: 'Свяжитесь с нами',
  },
  Blog: {
    et: 'Blogi',
    en: 'Blog',
    de: 'Blog',
    fi: 'Blogi',
    ru: 'Блог',
  },
  'In English': {
    et: 'Inglise keeles',
    en: 'In English',
    de: 'Auf Englisch',
    fi: 'Englanniksi',
    ru: 'На английском',
  },
  'In Estonian': {
    et: 'Eesti keeles',
    en: 'In Estonian',
    de: 'Auf Estnisch',
    fi: 'Viroksi',
    ru: 'На эстонском',
  },
  'Front page': {
    et: 'Esileht',
    en: 'Front page',
    de: 'Startseite',
    fi: 'Etusivu',
    ru: 'Главная страница',
  },
  'Products page': {
    et: 'Toodete leht',
    en: 'Products page',
    de: 'Produktseite',
    fi: 'Tuotesivu',
    ru: 'Каталог товаров',
  },
  Menu: {
    et: 'Menüü',
    en: 'Menu',
    de: 'Menü',
    fi: 'Valikko',
    ru: 'Меню',
  },
  Partners: {
    et: 'Koostööpartnerid',
    en: 'Partners',
    de: 'Geschäftspartner',
    fi: 'Yhteistyökumppanit',
    ru: 'Партнеры',
  },
  Social: {
    et: 'Sotsiaalmeedia',
    en: 'Social',
    de: 'Sozial',
    fi: 'Sosiaalinen media',
    ru: 'Социальные сети',
  },
  'Blog and News': {
    et: 'Blogi ja uudised',
    en: 'Blog and News',
    de: 'Blog und Nachrichten',
    fi: 'Blogi ja uutiset',
    ru: 'Блог и новости',
  },
  'See more': {
    et: 'Vaata lähemalt',
    en: 'See more',
    de: 'Mehr sehen',
    fi: 'Lue lisää',
    ru: 'Посмотреть больше',
  },
  'Eco products': {
    en: 'Eco products',
    et: 'Ökotooted',
    de: 'Öko-Produkte',
    fi: 'Ekotuotteet',
    ru: 'Эко товары',
  },
  'New products': {
    en: 'New products',
    et: 'Uued tooted',
    de: 'Neue Produkte',
    fi: 'Uudet tuotteet',
    ru: 'Новые товары',
  },
  'Be Creative': {
    en: 'Be Creative',
    et: 'Ole loominguline',
    de: 'Sei kreativ',
    fi: 'Ole luova',
    ru: 'Будь креативным',
  },
  'Special discount': {
    en: 'Special discount',
    et: 'Eriallahindlused',
    de: 'Sonderangebote',
    fi: 'Erityisalennus',
    ru: 'Специальные скидки',
  },
  'Best sellers': {
    en: 'Best sellers',
    et: 'Enim müüdud',
    de: 'Bestseller',
    fi: 'Myydyimmät',
    ru: 'Бестселлеры',
  },
  'No products in this category': {
    en: 'No products in this category',
    et: 'Selles kategoorias hetkel tooted puuduvad',
    de: 'Keine Produkte in dieser Kategorie',
    fi: 'Tässä kategoriassa ei ole tuotteita',
    ru: 'Товаров в этой категории нет',
  },
  Back: {
    en: 'Back',
    et: 'Tagasi',
    de: 'Zurück',
    fi: 'Takaisin',
    ru: 'Назад',
  },
  'Close menu': {
    en: 'Close menu',
    et: 'Sulge menüü',
    de: 'Menü schließen',
    fi: 'Sulje valikko',
    ru: 'Закрыть меню',
  },
  'All categories': {
    en: 'All categories',
    et: 'Kõik kategooriad',
    de: 'Alle Kategorien',
    fi: 'Kaikki kategoriat',
    ru: 'Все категории',
  },
  'Search for products': {
    en: 'Search for products',
    et: 'Otsi tooteid',
    de: 'Produkte suchen',
    fi: 'Etsi tuotteita',
    ru: 'Поиск товаров',
  },
  'Set custom values': {
    en: 'Set custom values',
    et: 'Muuda väärtusi',
    de: 'Benutzerdefinierte Werte festlegen',
    fi: 'Aseta mukautetut arvot',
    ru: 'Задать индивидуальные значения',
  },
  Quantity: {
    et: 'Kogus',
    en: 'Quantity',
    fi: 'Määrä',
    de: 'Menge',
    ru: 'Количество',
  },
  'Number of printed colors': {
    en: 'Number of printed colors',
    et: 'Prinditud värvide arv',
    de: 'Anzahl der gedruckten Farben',
    fi: 'Painettujen värien määrä',
    ru: 'Количество цветов печати',
  },
  'Delivery date': {
    en: 'Delivery date',
    et: 'Kohaletoimetamise aeg',
    de: 'Lieferdatum',
    fi: 'Toimituspäivä',
    ru: 'Дата доставки',
  },
  Remove: {
    en: 'Remove',
    et: 'Eemalda',
    fi: 'Poista',
    de: 'Entferne',
    ru: 'Удали',
  },
  'Your order has been sent to us!': {
    en: 'Your order has been sent to us!',
    et: 'Sinu tellimus on edastatud!',
    fi: 'Tilauksesi on lähetetty!',
    de: 'Ihre Bestellung wurde an uns übermittelt!',
    ru: 'Ваш заказ был отправлен нам!',
  },
  'We will contact you as soon as possible': {
    en: 'We will contact you as soon as possible',
    et: 'Võtame Sinuga ühendust niipea kui võimalik',
    fi: 'Otamme sinuun yhteyttä mahdollisimman pian',
    de: 'Wir werden uns so bald wie möglich mit Ihnen in Verbindung setzen',
    ru: 'Мы свяжемся с вами как можно скорее',
  },
  'Go back to homepage': {
    en: 'Go back to homepage',
    et: 'Tagasi esilehele',
    fi: 'Takaisin etusivulle',
    de: 'Zur Startseite zurückkehren',
    ru: 'Вернуться на главную страницу',
  },
  'Contact form': {
    en: 'Contact form',
    et: 'Kontaktivorm',
    fi: 'Yhteydenottolomake',
    de: 'Kontaktformular',
    ru: 'Форма контакта',
  },
  'Products in cart': {
    en: 'Products in cart',
    et: 'Tooted ostukorvis',
    fi: 'Ostoskorin tuotteet',
    de: 'Produkte im Warenkorb',
    ru: 'Товары в корзине',
  },
  'Clear the cart': {
    en: 'Clear the cart',
    et: 'Tühjenda korv',
    fi: 'Tyhjennä ostoskori',
    de: 'Warenkorb leeren',
    ru: 'Очистить корзину',
  },
  'Company name': {
    en: 'Company name',
    et: 'Firma nimi',
    fi: 'Yrityksen nimi',
    de: 'Firmenname',
    ru: 'Название компании',
  },
  'Contact person': {
    en: 'Contact person',
    et: 'Kontaktisik',
    fi: 'Yhteyshenkilö',
    de: 'Ansprechpartner',
    ru: 'Контактное лицо',
  },
  'Please input the contact person name': {
    en: 'Please input the contact person name',
    et: 'Palun sisesta kontaktisiku nimi',
    fi: 'Syötä yhteyshenkilön nimi',
    de: 'Bitte geben Sie den Namen des Ansprechpartners ein',
    ru: 'Пожалуйста, введите имя контактного лица',
  },
  'Contact person name': {
    en: 'Contact person name',
    et: 'Kontaktisiku nimi',
    fi: 'Yhteyshenkilön nimi',
    de: 'Name des Ansprechpartners',
    ru: 'Имя контактного лица',
  },
  Email: {
    en: 'Email',
    et: 'E-post',
    fi: 'Sähköpostiosoite',
    de: 'E-Mail',
    ru: 'Электронная почта',
  },
  'The input is not valid E-mail!': {
    en: 'The input is not a valid E-mail!',
    et: 'Sisestatud e-post ei ole korrektne',
    fi: 'Syöttämäsi sähköpostiosoite on virheellinen',
    de: 'Die Eingabe ist keine gültige E-Mail!',
    ru: 'Введенный адрес не является действительным!',
  },
  'Please input your E-mail!': {
    en: 'Please input your E-mail!',
    et: 'Palun sisesta e-post!',
    fi: 'Syötä sähköpostiosoitteesi',
    de: 'Bitte geben Sie Ihre E-Mail ein!',
    ru: 'Пожалуйста, введите вашу электронную почту!',
  },
  'E-mail': {
    en: 'E-mail',
    et: 'E-post',
    fi: 'Sähköpostiosoite',
    de: 'E-Mail',
    ru: 'Электронная почта',
  },
  'Phone nr': {
    en: 'Phone nr',
    et: 'Telefoni number',
    fi: 'Puhelinnumero',
    de: 'Ihre Telefonnummer',
    ru: 'Телефон',
  },
  'Please insert your phone number': {
    en: 'Please insert your phone number',
    et: 'Palun sisesta telefoni number',
    fi: 'Syötä puhelinnumerosi',
    de: 'Bitte geben Sie Ihre Telefonnummer ein',
    ru: 'Пожалуйста, введите ваш номер телефона',
  },
  'Additional comments': {
    en: 'Additional comments',
    et: 'Lisakommentaarid',
    fi: 'Lisäkommentit',
    de: 'Zusätzliche Anmerkungen',
    ru: 'Дополнительные комментарии',
  },
  Files: {
    en: 'Files',
    et: 'Failid',
    fi: 'Tiedostot',
    de: 'Dateien',
    ru: 'Файлы',
  },
  'Click or drag file to this area to upload': {
    et: 'Kliki või tõmba fail siia',
    en: 'Click or drag file to this area to upload',
    fi: 'Napsauta tai vedä tiedosto tähän',
    ru: 'Кликните или перетащите файл сюда для загрузки',
    de: 'Zum Hochladen klicken oder Datei in dieses Feld ziehen',
  },
  'Support for a single or bulk upload': {
    en: 'Support for a single or bulk upload (max 3)',
    et: 'Lae üks või mitu faili (max 3)',
    fi: 'Lataa yksi tai useampi tiedosto (enintään 3)',
    de: 'Unterstützung für Einzel- oder Massenupload (max. 3)',
    ru: 'Поддержка одиночной или массовой загрузки (макс. 3)',
  },
  'Send the query': {
    en: 'Send the query',
    et: 'Saada päring',
    fi: 'Lähetä tiedustelu',
    de: 'Anfrage senden',
    ru: 'Отправить запрос',
  },
  Loading: {
    en: 'Loading',
    et: 'Laeb',
    fi: 'Ladataan',
    de: 'Laden',
    ru: 'Загрузка',
  },
  'Estimated final price': {
    en: 'Estimated final price',
    et: 'Umbkaudne lõplik hind',
    fi: 'Arvioitu lopullinen hinta',
    de: 'Geschätzter Endpreis',
    ru: 'Предварительная итоговая цена',
  },
  'Oh no! Something went wrong. Please refresh the page!': {
    en: 'Oh no! Something went wrong. Please refresh the page!',
    et: 'Oi! Midagi läks valesti. Palun lae leht uuesti!',
    fi: 'Voi ei! Jotain meni pieleen. Lataa sivu uudelleen',
    de:
      'Oh nein! Etwas ist schief gelaufen. Bitte aktualisieren Sie die Seite!',
    ru: 'Ой! Произошла ошибка. Пожалуйста, обновите страницу!',
  },
  'Permission to store data': {
    en: 'Permission to store data',
    et: 'Luba andmeid salvestada',
    fi: 'Lupa tallentaa tietoja',
    de: 'Erlaubnis zur Datenspeicherung',
    ru: 'Разрешение на хранение данных',
  },
  'I give permission to store the data provided information in order to fulfill the order': {
    en:
      'I give permission to store the data provided information in order to fulfill the order',
    et: 'Annan loa enda andmed salvestada selleks, et saaksite täita tellimuse',
    fi: 'Annan luvan tietojeni tallentamiseen tilauksen käsittelemiseksi',
    de:
      'Ich gebe die Erlaubnis, die bereitgestellten Daten zur Bestellabwicklung zu speichern',
    ru:
      'Я даю разрешение на хранение предоставленной информации для выполнения заказа.',
  },
  Subject: {
    en: 'Subject',
    et: 'Teema',
    fi: 'Aihe',
    de: 'Betreff',
    ru: 'Тема',
  },
  Message: {
    en: 'Message',
    et: 'Sõnum',
    fi: 'Viesti',
    de: 'Nachricht',
    ru: 'Сообщение',
  },
  'Contact Us': {
    en: 'Contact Us',
    et: 'Kontakteeru meiega',
    fi: 'Ota meihin yhteyttä',
    de: 'Kontaktieren Sie uns',
    ru: 'Свяжитесь с нами',
  },
  Color: {
    et: 'Värvus',
    en: 'Color',
    fi: 'Väri',
    de: 'Farbe',
    ru: 'Цвет',
  },
  Dimensions: {
    et: 'Mõõtmed',
    en: 'Dimensions',
    fi: 'Mitat',
    de: 'Abmessungen',
    ru: 'Размеры',
  },
  'Items in stock': {
    et: 'Laoseis',
    en: 'Items in stock',
    fi: 'Varastotilanne',
    de: 'Artikel auf Lager',
    ru: 'Товары в наличии',
  },
  'Coming into stock': {
    et: 'Lattu tulemas',
    en: 'Coming into stock',
    fi: 'Tulossa varastoon',
    de: 'Bald auf Lager',
    ru: 'Скоро в наличии',
  },
  'Number of colors': {
    et: 'Trükivärvide arv',
    en: 'Number of imprint colors',
    fi: 'Painovärien määrä',
    de: 'Anzahl der Druckfarben',
    ru: 'Количество цветов печати',
  },
  'Price class': {
    et: 'Hinnaklass',
    en: 'Price class',
    fi: 'Hintaluokka',
    de: 'Preisgruppe',
    ru: 'Ценовой класс',
  },
  'Order now': {
    et: 'Tellige kohe',
    en: 'Order now',
    fi: 'Tilaa heti',
    de: 'Jetzt bestellen',
    ru: 'Заказать сейчас',
  },
  'Ask offer': {
    et: 'Küsige pakkumist',
    en: 'Ask offer',
    fi: 'Pyydä tarjous',
    de: 'Angebot anfragen',
    ru: 'Запросить предложение',
  },
  'Add to query': {
    et: 'Lisage päringuvormi',
    en: 'Add to query',
    fi: 'Lisää tiedustelulomakkeeseen',
    de: 'Zur Anfrage hinzufügen',
    ru: 'Добавить в запрос',
  },
  'Price on request': {
    et: 'Küsige täpsemat hinda',
    en: 'Price on request',
    fi: 'Kysy tarkkaa hintaa',
    de: 'Preis auf Anfrage',
    ru: 'Цена по запросу',
  },
  'This product cannot be printed upon': {
    et: 'Tootele ei saa teostada pealetrükki',
    en: 'This product cannot be printed upon',
    fi: 'Tähän tuotteeseen ei voida painaa',
    de: 'Auf dieses Produkt kann nicht gedruckt werden',
    ru: 'На этот товар нельзя печатать',
  },
  'Print on the product?': {
    et: 'Soovite tootele trükkida?',
    en: 'Print on the product?',
    fi: 'Haluatko painattaa tuotteeseen?',
    de: 'Auf dem Produkt drucken?',
    ru: 'Печать на продукте?',
  },
  'Minimum quantity': {
    et: 'Miinimumkogus',
    en: 'Minimum quantity',
    fi: 'Vähimmäismäärä',
    de: 'Mindestmenge',
    ru: 'Минимальное количество',
  },
  'Full-size image': {
    et: 'Täismõõdus pilt',
    en: 'Full-size image',
    fi: 'Täysikokoinen kuva',
    de: 'Bild in Originalgröße',
    ru: 'Изображение в полный размер',
  },
  'Wholesale price. The final price of the product depends on the quantity': {
    et: 'Hulgihind. Toote lõplik hind sõltub tellitavast kogusest',
    en:
      'Wholesale price. The final price of the product depends on the quantity',
    fi: 'Tukkuhinta. Tuotteen lopullinen hinta riippuu tilattavasta määrästä',
    de: 'Großhandelspreis. Der Endpreis des Produkts hängt von der Menge ab',
    ru: 'Оптовая цена. Итоговая цена товара зависит от количества',
  },
  'Sizes and stock': {
    et: 'Mõõtmed ja laoseisud',
    en: 'Sizes and stock',
    fi: 'Mitat ja varastotilanne',
    de: 'Größen und Lagerbestand',
    ru: 'Размеры и наличие',
  },
  Size: {
    et: 'Suurus',
    en: 'Size',
    fi: 'Koko',
    de: 'Größe',
    ru: 'Размер',
  },
  Stock: {
    et: 'Laoseis',
    en: 'Stock',
    fi: 'Varastotilanne',
    de: 'Lager',
    ru: 'Наличие',
  },
  'Please enter the quantity': {
    et: 'Palun sisesta kogus',
    en: 'Please enter the quantity',
    fi: 'Syötä määrä',
    ru: 'Пожалуйста, введите количество',
    de: 'Bitte geben Sie die Menge ein',
  },
  'Please fill additional fields': {
    et: 'Palun täitke lisa väljad',
    en: 'Please fill additional fields',
    fi: 'Täytä lisäkentät',
    ru: 'Пожалуйста, заполните дополнительные поля',
    de: 'Bitte füllen Sie zusätzliche Felder aus',
  },
  Portfolio: {
    et: 'Portfoolio',
    en: 'Portfolio',
    fi: 'Portfolio',
    ru: 'Портфолио',
    de: 'Portfolio',
  },
  'The showcase of what we have done': {
    et: 'Vaata, milliseid ideid oleme varem ellu viinud',
    en: 'The showcase of what we have done',
    fi: 'Katso, millaisia ideoita olemme toteuttaneet',
    ru: 'Портфолио наших выполненных работ',
    de: 'Schaukasten unserer Bestellungen',
  },
  'No items in this category': {
    et: 'Kategoorias puuduvad tooted',
    en: 'No items in this category',
    fi: 'Tässä kategoriassa ei ole tuotteita',
    ru: 'Товаров в этой категории нет',
    de: 'Keine Artikel in dieser Kategorie',
  },
  'Contact - Kaubad.ee': {
    et: 'Kontakt - Kaubad.ee',
    en: 'Contact - Kaubad.ee',
    fi: 'Yhteystiedot - Kaubad.ee',
    ru: 'Контакт - Kaubad.ee',
    de: 'Kontakt - Kaubad.ee',
  },
  '404 Not Found - Kaubad.ee': {
    et: '404 Not Found - Kaubad.ee',
    en: '404 Not Found - Kaubad.ee',
    fi: '404 Not Found - Kaubad.ee',
    ru: '404 Not Found - Kaubad.ee',
    de: '404 Not Found - Kaubad.ee',
  },
  '500 Unknown error - Kaubad.ee': {
    et: '500 Unknown error - Kaubad.ee',
    en: '500 Unknown error - Kaubad.ee',
    fi: '500 Unknown error - Kaubad.ee',
    ru: '500 Unknown error - Kaubad.ee',
    de: '500 Unknown error - Kaubad.ee',
  },
  'Page not found - Kaubad.ee': {
    et: 'Lehekülge ei leitud - Kaubad.ee',
    en: 'Page not found - Kaubad.ee',
    fi: 'Page not found - Kaubad.ee',
    ru: 'Page not found - Kaubad.ee',
    de: 'Page not found - Kaubad.ee',
  },
  'Meet us': {
    et: 'Saage tuttavaks',
    en: 'Meet us',
    fi: 'Tutustu meihin',
    ru: 'Встретьте нас',
    de: 'Treffen Sie uns',
  },
  'Promotional Gifts, Promotional Items & Business Gifts': {
    et: 'Reklaamkingitused, Meened ja Ärikingitused',
    en: 'Promotional Gifts, Promotional Items & Business Gifts',
    fi: 'Mainoslahjat, Mainostuotteet & Liikelahjat',
    ru: 'Рекламные подарки, рекламные товары и деловые подарки',
    de: 'Werbegeschenke, Werbeartikel & Geschäftsgeschenke',
  },
  'We offer different promotional gifts, promotional items, business gifts to our customers. Products with a logo for sale or gift to customers and employees': {
    et: 'Pakume klientidele laias valikus erinevaid reklaamtooteid, meeneid, firmakingitusi. Logoga tooted müügiks või kinkimiseks klientidele ja töötajatele.',
    en: 'We offer different promotional gifts, promotional items, business gifts to our customers. Products with a logo for sale or gift to customers and employees',
    fi: 'Tarjoamme asiakkaillemme erilaisia mainoslahjoja, mainostuotteita ja liikelahjoja. Logolla varustetut tuotteet myyntiin tai lahjaksi asiakkaille ja työntekijöille.',
    ru: 'Мы предлагаем различные рекламные подарки, рекламные товары и деловые подарки нашим клиентам. Продукция с логотипом для продажи или в подарок клиентам и сотрудникам.',
    de: 'Wir bieten unseren Kunden verschiedene Werbegeschenke, Werbeartikel und Geschäftsgeschenke an. Produkte mit einem Logo zum Verkauf oder als Geschenk für Kunden und Mitarbeiter.',
  },
  'About - Kaubad.ee': {
    et: 'Meist - Kaubad.ee',
    en: 'About - Kaubad.ee',
    fi: 'Tietoa - Kaubad.ee',
    ru: 'О нас - Kaubad.ee',
    de: 'Über - Kaubad.ee',
  },
  'We value a personalised partnership': {
    et: 'Väärtustame personaalset partnerlussuhet',
    en: 'We value a personalised partnership',
    fi: 'Arvostamme henkilökohtaista kumppanuutta',
    ru: 'Мы ценим персонализированное партнерство',
    de: 'Wir schätzen eine personalisierte Partnerschaft',
  },
  'Privacy': {
    et: 'Privaatsus',
    en: 'Privacy',
    fi: 'Yksityisyys',
    ru: 'Конфиденциальность',
    de: 'Datenschutz',
  },
  'Entered quantity too small. Please contact us for a custom solution.': {
    et: 'Sisestatud kogus on liiga väike. Erilahenduse saamiseks võtke meiega ühendust.',
    en: 'Entered quantity too small. Please contact us for a custom solution.',
    fi: 'Syötetty määrä on liian pieni. Ota yhteyttä meihin saadaksesi räätälöidyn ratkaisun.',
    ru: 'Введенное количество слишком мало. Пожалуйста, свяжитесь с нами для индивидуального решения.',
    de: 'Eingegebene Menge zu klein. Bitte kontaktieren Sie uns für eine individuelle Lösung.'
  }
}
