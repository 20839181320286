export const MAIN_PADDING = '35px';
export const MAIN_PADDING_2 = '70px';

export const MAX_BLOCK_WIDTH = '1600px';
export const MAX_CONTENT_WIDTH = '1240px';

export const MAIN_COLOR = '#1890ff';
export const DARK_BLUE = '#4B5068';

export const BREAKPOINTS = {
  XS: '375px',
  SM: '712px',
  MD: '1280px',
  LG: '1330px'
};
